<template>
  <div id="main-wrapper" class="inner-pages forex-page mt4-page">
    <div class="section banner-holder">
      <div class="forex-nav">
        <div class="wrapper">
          <ul>
            <li><router-link to="/mt4">MT4</router-link></li>
            <li class="active"><router-link to="/mt5">MT5</router-link></li>
            <li><router-link to="/act-trader">ActTrader</router-link></li>
            <li><router-link to="/zulu">ZuluTrade</router-link></li>
          </ul>
        </div>
      </div>

      <div
        class="title-holder t-white t-center center-div wow fadeIn"
        data-wow-delay="1.6s"
      >
        <h2>MetaTrader 5</h2>
        <div class="img-holder">
          <img src="assets/images/mt5-img.webp" alt="AAAFx" title="AAAFx" width="765" height="414" class="fluid-img" />
        </div>
      </div>
    </div>
    <div class="section content-mt4">
      <div class="wrapper">
        <div class="title-holder">
          <h6>
            MT5 platform was released in 2010 supporting the faster than ever
            processing technology and additional layouts.
          </h6>
          <br />
          <p>
            AAAFx MT5 platform offers an extensive list of the trading
            instruments including Cryptocurrency CFDs, helping the advanced
            platform to fulfil its futuristic outlook.
          </p>
        </div>
      </div>
    </div>
    <div class="section forex-content">
      <div class="wrapper">
        <div class="content-sec">
          <div class="img-holder wow fadeInLeft">
            <img src="assets/images/mt5-img1.webp" alt="AAAFx" title="AAAFx" width="700" height="401" class="fluid-img" />
          </div>
          <div class="content-holder wow fadeInRight">
            <img src="assets/images/mt5-ico1.webp" alt="AAAFx" title="AAAFx" width="371" height="125" class="fluid-img" />
            <br /><br />
            <p>
              It's always better on a bigger screen. Download our MT5 platform
              on your preferred device.
            </p>
          </div>
          <div class="btn-holder wow fadeIn">
            <a
              :href="static_vars.platformLinks.mt5.desktop"
              class="btn btn-grey"
              title="MT5 Desktop"
            >
              <img src="assets/images/desk-ico1.webp" alt="AAAFx" title="AAAFx" width="69" height="49" class="fluid-img" />
              {{
                static_vars.platformLinks.mt5.desktop == "javascript:void(0);"
                  ? "Coming Soon"
                  : "Download MT5 Desktop"
              }}
              <br />
            </a>
            <a
              :href="static_vars.platformLinks.mt5.mac"
              class="btn btn-grey"
              title="MT5 Mac"
            >
              <img src="assets/images/desk-ico2.webp" alt="AAAFx" title="AAAFx" width="69" height="49" class="fluid-img" />
              {{
                static_vars.platformLinks.mt5.mac == "javascript:void(0);"
                  ? "Coming Soon"
                  : "Download MT5 Mac"
              }}
            </a>
            <a
              :href="static_vars.platformLinks.mt5.web"
              class="btn btn-grey"
              title="MT5 Web"
              target="_blank"
            >
              <img src="assets/images/desk-ico3.webp" alt="AAAFx" title="AAAFx" width="69" height="49" class="fluid-img" />
              {{ (static_vars.platformLinks.mt5.web == "javascript:void(0);" || static_vars.platformLinks.mt5.web == "")   ? "Coming Soon"   : "Web Based MT5"}}
            </a>
          </div>
        </div>
        <div class="section-small wow fadeIn">
          <div class="accordion-holder t-left">
            <h3 v-on:click="desktop ^= true" :class="[{ active: desktop }]">
              Steps to download MT5 Desktop
            </h3>
            <div class="content" v-show="desktop">
              <p>
                1. Download the platform from above link. <br />
                2. Run the .exe file <br />
                3. Go to File> Open an account” <br />
                4. Type AAAFX and in 'Add New Broker' and click on 'Find your
                broker'. <br />
                5. Select “AAAFX” and click on “Next” <br />
                6. Click on “Connect with an existing trading account” and enter
                your login details <br />
                7. To log in to a Live account select server name
                'AAAFX-FX-REAL' and 'AAAFX-FX-DEMO' for Demo account from the
                drop-down menu and click on “Finish”.
              </p>
            </div>
            <h3
              v-on:click="uninstalldesktop ^= true"
              :class="[{ active: uninstalldesktop }]"
            >
              Steps to uninstall MT5 Desktop
            </h3>
            <div class="content" v-show="uninstalldesktop">
              <p>
                1. Click on Start button in Windows <br />
                2. The MT5 application will be included in list of programs.
                <br />
                3. Right click on the application name and select 'Uninstall'.
              </p>
            </div>
            <h3 v-on:click="web ^= true" :class="[{ active: web }]">
              Steps to log into Web-based MT5
            </h3>
            <div class="content" v-show="web">
              <p>
                1. On the Web-Trader. go to “File” option on the menu bar and
                select “Login to Trade account”. <br />
                2. Enter trading account ID and password. <br />
                3. Select server as “AAAFX-FX-REAL ” for live account and
                “AAAFX-FX-DEMO” for demo account <br />
                4. Then select the platform MT5 AND click on “OK”
              </p>
            </div>
          </div>
        </div>
        <div class="section-small">
          <div class="content-sec mt4-phone">
            <div class="content-holder wow fadeInLeft">
              <img src="assets/images/mt5-ico2.webp" alt="AAAFx" title="AAAFx" width="371" height="125" class="fluid-img" />
              <br /><br />
              <p>
                Take your trading euphoria anywhere you go with all the trading
                tools and indicators available for AAAFx mobile trading
                application.
              </p>
              <div class="btn-holder wow fadeIn">
                <a
                  :href="static_vars.platformLinks.mt5.android"
                  class="btn btn-grey"
                  title="MT5 Android"
                >
                  <img src="assets/images/phone-ico2.webp" alt="AAAFx" title="AAAFx" width="41" height="44" class="fluid-img" />
                  {{
                    static_vars.platformLinks.mt5.android ==
                    "javascript:void(0);"
                      ? "Coming Soon"
                      : "Download MT5 Android"
                  }}
                </a>
                <a
                  :href="static_vars.platformLinks.mt5.ios"
                  class="btn btn-grey"
                  title="MT5 iOS"
                >
                  <img src="assets/images/phone-ico1.webp" alt="AAAFx" title="AAAFx" width="41" height="44" class="fluid-img" />
                  {{
                    static_vars.platformLinks.mt5.ios == "javascript:void(0);"
                      ? "Coming Soon"
                      : "Download MT5 iOS"
                  }}
                </a>
              </div>
            </div>
            <div class="img-holder wow fadeInRight">
              <img src="assets/images/mt4-img2.webp" alt="AAAFx" title="AAAFx" width="650" height="392" class="fluid-img" />
            </div>
          </div>
        </div>

        <div class="section-small wow fadeIn">
          <div class="accordion-holder t-left">
            <h3 v-on:click="android ^= true" :class="[{ active: android }]">
              Steps to download MT5 Android
            </h3>
            <div class="content" v-show="android">
              <p>
                1. Download the application from above link. <br />
                2. Click on three horizontal lines in Menu and go to manage
                accounts”/ “Get started”. <br />
                3. Click on the “+” sign on the top right corner. <br />
                4. Under 'Find your broker' select AAAFX-FX-DEMO for demo
                account and AAAFX-FX-REAL for live account. Now enter your login
                ID and password and click on login.
              </p>
            </div>
            <h3
              v-on:click="unistallandroid ^= true"
              :class="[{ active: unistallandroid }]"
            >
              Steps to uninstall MT5 Android
            </h3>
            <div class="content" v-show="unistallandroid">
              <p>
                1. On the Menu, go to Settings > Applications > Manage
                applications <br />
                2. Find MT4 listed in there and tap on its name. <br />
                3. Tap 'Uninstall'.
              </p>
            </div>
            <h3 v-on:click="ios ^= true" :class="[{ active: ios }]">
              Steps to download MT5 iOS
            </h3>
            <div class="content" v-show="ios">
              <p>
                1. Download the application from above link. <br />
                2. Click on "Settings" and go to "New Account" Option, <br />
                3. Then click "Login to an existing Account" <br />
                4. Enter “AAAFX” under find broker. br 5. Select AAAFX-FX-DEMO
                for demo account and AAAFX-FX-REAL for live account. <br />
                6. Enter your Login ID and Password and click on "Sign in"
              </p>
            </div>
            <h3
              v-on:click="unistallios ^= true"
              :class="[{ active: unistallios }]"
            >
              Steps to uninstall MT5 iOS
            </h3>
            <div class="content" v-show="unistallios">
              <p>
                1. Long press on the MT5 application icon until all the icons on
                your screen begin to shake. <br />
                2. Click the 'x' in the upper left-hand corner of the icon for
                the MT5 application.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section content-mt4">
      <div class="wrapper">
        <div class="title-holder">
          <h3>
            Take your MT5 platform trading experience with AAAFx to the next
            level with the following features:
          </h3>
        </div>
        <div class="img-holder t-center">
          <img src="assets/images/mt5-img3.webp" alt="AAAFx" title="AAAFx" width="1158" height="361" class="fluid-img" />
        </div>
      </div>
    </div>
    <div class="section">
      <div class="wrapper">
        <div class="content-sec mt4-phone why-mt4 why-mt5">
          <div class="content-holder wow fadeInLeft">
            <div class="title-holder">
              <h3>Why AAAFx's MT5?</h3>
            </div>
            <p>Technical Analysis Tools:</p>
            <ul class="listing-check">
              <li>
                21-different time frames so that you never lose even a second of
                opportunity
              </li>
              <li>30+ built-in indicators and 40+ technical objects</li>
              <li>Availability of historical chart data of up to 30 years</li>
              <li>
                6 types on pending order types available along with the market
                execution
              </li>
              <li>In-built and modifying option for Scripts</li>
            </ul>
            <br />
            <p>Fundamental Analysis Tools:</p>
            <ul class="listing-check">
              <li>
                Built-in Economic Calendar; keeping you updated about every
                volatile event
              </li>
              <li>
                Ability to download the detailed account statement for
                customizable time period
              </li>
              <li>
                Depth of market tool giving you an edge on your trading
                strategies
              </li>
            </ul>
          </div>
          <div class="img-holder wow fadeInRight">
            <img src="assets/images/mt5-img4.webp" alt="AAAFx" title="AAAFx" width="575" height="355" class="fluid-img" />
            <div class="btn-holder">
              <a :href="siteurl2" target="_blank" class="btn btn-yellow"
                >OPEN LIVE ACCOUNT</a
              >
              <a :href="siteurl2" target="_blank" class="btn btn-blue"
                >OPEN DEMO ACCOUNT</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      Support: false,
      Finance: false,
      Administrator: false,
      desktop: false,
      uninstalldesktop: false,
      web: false,
      android: false,
      unistallandroid: false,
      ios: false,
      unistallios: false,
      siteurl: process.env.VUE_APP_CLIENT_PORTAL_URL,
      siteurl2: process.env.VUE_APP_CLIENT_REGISTER_URL,
    };
  },
};
</script>
